@import "../variables";
@import "./variables";
@import "./mixins";
@import "./../theme";

.month-calendar {
  .react-datepicker-wrapper {
    display: inline-block;
    padding: 0;
    border: 0;
  }

  .react-datepicker {
    font-family: $datepicker__font-family;
    font-size: $datepicker__font-size;
    background-color: #fff;
    color: $datepicker__text-color;
    border: none;
    display: inline-block;
    position: relative;
    width: 100%;
  }

  .react-datepicker--time-only {
    .react-datepicker__triangle {
      left: 35px;
    }

    .react-datepicker__time-container {
      border-left: 0;
    }

    .react-datepicker__time {
      border-radius: 0.3rem;
    }

    .react-datepicker__time-box {
      border-radius: 0.3rem;
    }
  }

  .react-datepicker__triangle {
    position: absolute;
    left: 50px;
  }

  .react-datepicker-popper {
    z-index: 1;

    &[data-placement^="bottom"] {
      margin-top: $datepicker__triangle-size + 2px;

      .react-datepicker__triangle {
        @extend %triangle-arrow-up;
      }
    }

    &[data-placement="bottom-end"],
    &[data-placement="top-end"] {
      .react-datepicker__triangle {
        left: auto;
        right: 50px;
      }
    }

    &[data-placement^="top"] {
      margin-bottom: $datepicker__triangle-size + 2px;

      .react-datepicker__triangle {
        @extend %triangle-arrow-down;
      }
    }

    &[data-placement^="right"] {
      margin-left: $datepicker__triangle-size;

      .react-datepicker__triangle {
        left: auto;
        right: 42px;
      }
    }

    &[data-placement^="left"] {
      margin-right: $datepicker__triangle-size;

      .react-datepicker__triangle {
        left: 42px;
        right: auto;
      }
    }
  }

  .react-datepicker__header {
    text-align: center;
    position: relative;
    display: flex;
    flex-direction: column;
    border: none;
    background-color: unset;

    &--time {
      padding-bottom: 8px;
      padding-left: 5px;
      padding-right: 5px;
    }
  }



  .react-datepicker__current-month,
  .react-datepicker-time__header,
  .react-datepicker-year-header {
    display: flex;
    align-items: center;
    align-self: center;
    -webkit-appearance: none;
    box-sizing: border-box;
    border: none;
    clear: both;
    cursor: pointer;
    font-family: inherit;
    font-size: 1.6rem;
    font-weight: 400;
    min-height: 4.4rem;
    outline: none;
    padding: .375rem 1.5rem;
    position: relative;
    transition: all .2s ease-in-out;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    color: #6c757d;
    border-radius: 0;
    margin-bottom: 2rem;
  }


  .react-datepicker__navigation {
    height: 4rem;
    width: 4rem;
    text-align: center;
    cursor: pointer;
    position: absolute;
    top: 58%;
    padding: 0;
    border: none;
    z-index: 1;
    text-indent: -999em;
    overflow: hidden;

    &--previous {
      left: -25px;
      background: #EBEBEB url("../../images/arrow-left.png") no-repeat center;

      &:hover {
        border-right-color: unset;
        background: #ebebeb url("../../images/arrow-left-active.png") no-repeat center;
      }

      &--disabled,
      &--disabled:hover {
        border-right-color: unset;
        cursor: default;
      }
    }

    &--next {
      right: -25px;
      background: #ebebeb url("../../images/arrow-right.png") no-repeat center;
      // border-left-color: $datepicker__muted-color;
      &--with-time:not(&--with-today-button) {
        right: 80px;
      }

      &:hover {
        border-left-color: unset;
        background: #ebebeb url("../../images/arrow-right-active.png") no-repeat center;
      }

      &--disabled,
      &--disabled:hover {
        border-left-color: unset;
        cursor: default;
      }
    }

    &--years {
      position: relative;
      top: 0;
      display: block;
      margin-left: auto;
      margin-right: auto;

      &-previous {
        top: 4px;
        border-top-color: $datepicker__muted-color;

        &:hover {
          border-top-color: darken($datepicker__muted-color, 10%);
        }
      }

      &-upcoming {
        top: -4px;
        border-bottom-color: $datepicker__muted-color;

        &:hover {
          border-bottom-color: darken($datepicker__muted-color, 10%);
        }
      }
    }

    &:hover {
      background-color: $main-color;
    }
  }

  .react-datepicker__day {
    font-size: $base-font-size;
    line-height: 5rem;
    // width: 5rem;
    display: flex;
    // flex: 1;
    height: 3rem;
    width: 3rem;
    margin: 1rem;
    justify-content: center;
    align-items: center;
    outline: none;

    &.available, &.disabled {
      color: #35E4A1;
      background-color: #E0F7EF;
      font-weight: 600;
      border-radius: 50%;

      &.react-datepicker__day--selected {
        color: $white-color;
        background-color: $theme-color;
        outline: none;
      }

      &.react-datepicker__day--disabled {
        color: #ccc;;
        background-color: transparent;
        font-weight: 400;
      }
    }

    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
      pointer-events: none;
    }

    &.react-datepicker__day--outside-month {
      color: #ccc;
    }
  }


  .react-datepicker__portal {
    .react-datepicker__current-month,
    .react-datepicker-time__header {
      font-size: $datepicker__font-size * 1.8;
    }

    .react-datepicker__navigation {
      border: none;
    }
  }

  &.event-calendar {
    display: flex;
    justify-content: center;
    align-items: center;
    .react-datepicker {
      width: 100%;
      .react-datepicker__month-container {
        width: unset;
        float: unset;
      }
    }

    .react-datepicker__week {
      justify-content: center;
      :first-child {
        border-left: 0;
      }
      :last-child {
        border-right: 0;
      }
      &:first-child {
        .react-datepicker__day {
          border-top: 0;
        }
      }
      &:last-child {
        .react-datepicker__day {
          border-bottom: 0;
        }
      }
    }
    .react-datepicker__day {
      border: 0.1rem solid rgba(#d0d0d0, 0.5);
      height: 6rem;
      width: 7rem;
      margin: 0;
      line-height: unset;
      justify-content: flex-end;
      align-items: flex-start;
      padding: 1rem;
      flex-direction: column;
      font-size: $small-font-size;
      color: $grey-text-color;
      font-weight: 300;
      font-family: 'Gilroy-Light';

      &:hover {
        border-radius: 0;
        background-color: $datepicker__background-color;
      }
    }
    .react-datepicker__month {
      margin: 0;

      .react-datepicker__week {
        .react-datepicker__day--keyboard-selected,
        .react-datepicker__month-text--keyboard-selected,
        .react-datepicker__quarter-text--keyboard-selected {
          border-radius: 0.3rem;
          background-color: rgba(0, 0, 0, 0.05);
          color: $datepicker__text-color;
        }

        .react-datepicker__day--selected {
          &.react-datepicker__day--selected {
            color: $grey-text-color;
            background-color: $white-color;
            border:solid 0.4rem #148086;
            padding: 0.6rem;
            border-radius: 0;
            outline: none;
          }

          &:hover {
            opacity: 0.5;
          }
        }

        .react-datepicker__day--today {
          border-radius: 0;
          background-color: rgba(0, 0, 0, 0.05);
          color: $datepicker__text-color;
        }
      }
    }

    .react-datepicker__current-month,
    .react-datepicker-time__header,
    .react-datepicker-year-header {
      align-self: flex-start;
      line-height: 1.33;
      font-size: $m-font-size;
      font-weight: 800;
      color: $view-title-color;
      //border: thin solid #d2d8dd;
    }

    .react-datepicker__navigation {
      width: 0.9rem;
      height: 1.5rem;
      &--previous {
        left: unset;
        top: 2.275rem;
        right: 6rem;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAABLCAMAAADTaf7vAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAGBQTFRFAAAA29zcUlpagYaG09XVSlJSP0dHXWRky83NRk5OV19fwsXFQ0xMuLu7QElJrrKyVFxcpaqqS1NTRE1NxsjIz9HR19nZ3uDgT1ZW5ubm5ebm6+vr6+zsXGNjZmxsWGBgXN+p9QAAACB0Uk5TAP///////////////////////////////6D/QP/////VkKvtAAAAiElEQVR4nN3VSRLCMAxE0SSAmcOYgfn+tyRfi7igXK5s1W/9t2oVRVaJCjPkY4/1HAsELCFVr7BGGG2gUm+xQ/ilUu9RIyQI1AekmugIt/UJ+dCc4ba+QL6+InW2UrVp8DfBerVpEd9pikBtOvRQrs3tPrAJdlZjcm0ez8ELzmpMrqM3PvBafwHm8lZDntIVVwAAAABJRU5ErkJggg==) no-repeat center;
        background-size: contain;
      }

      &--next {
        right: 0;
        top: 2.275rem;
        background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAC0AAABLCAMAAADTaf7vAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAAGBQTFRFWGBgZmxsAAAAP0dHXGNj7+/v6+zsVFxc5eXl5ebmT1ZW3uDgSlJS19nZS1NTRk5Oz9HRQ0xMxsjIRE1NpaqqrrKyV19fQElJuLu7wsXFy83NXWRk09XVgYaGUlpa29zcrC5ZIgAAACB0Uk5T//8A//8Q//9k//////////////////////////////9slj+dAAAAkElEQVR4nN3VyQ6CQBBF0RYnUBSZHHDg//9S7jMxxjTKtuqsb++qq0LADMl/wWo9f1tgicn1amCsljVSTK6zgbFaNtjCcy05dnBQ7zH6pMABnmspUcFzLTXc1w3M1lrBv8MWr/m2Wh8Ra04442NXear1bS/4vjtOaq3gDkmE2foKhTqnN8RC2/UdD/QYDU3XT4NDVIEUgCgtAAAAAElFTkSuQmCC) no-repeat center;
        background-size: contain;
      }
    }

    .react-datepicker__day {
      position: relative;
      color: $grey-text-color;
      background-color: $table-row-background-color;

      &.react-datepicker__day--outside-month {
        opacity: 0.3;
      }
      &.react-datepicker__day--disabled {
        color: rgba(102, 102, 102, 0.3);
        background-color: transparent;
        font-weight: 400;
      }
    }

    & > div {
      display: flex;
      justify-content: center;
      width: 100%;
      height: 100%;
    }
  }
}
