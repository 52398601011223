@import "~bootstrap/scss/bootstrap";
@import "font/feather";
@import "./datepicker/styles";
@import "./radio-input";
@import "./select";

@import "~meditrip-styles/normalize";
@import "~meditrip-styles/panel/theme-variables";
@import "~meditrip-styles/panel/style";
@import "~meditrip-styles/form-controls/style";
@import "~meditrip-styles/emoji/emoji";
// @import "calendar-datepicker/styles";

.form-label {
  display: block;
  width: 100%;
  text-align: left;
  font-weight: 400;
  margin-bottom: .3rem;
  color: #666;
  font-size: 1.4rem;
  min-height: 1.7rem;
}

label[for=reminderAmount] {
  width: 22rem;
}

.event-form-container {
  form {
    width: 100%;
  }
}

form {
  .custom-input-group {
    flex-direction: row-reverse;
    margin-top: 2.3rem;
    align-items: center;
    justify-content: flex-end;

    label {
      width: auto;
      margin-left: 1.5rem;
    }

    .basic-single {
      width: 50%;
    }
  }

  .form-row {
    .input-group {
      textarea {
        resize: vertical;
      }
    }
  }
}

.auth-form {
  .checkbox-container {
    .checkbox-wrapper {
      padding-left: 0;

      .checkbox-label {
        font-size: $base-m-font-size;

        a {
          color: $theme-color;
          font-size: $base-m-font-size;
        }
      }

      .checkbox-checkmark {
        display: none;
      }
    }
  }

  .basic-single {
    .select__control {
      height: 4.5rem;

      .select__value-container {
        height: 100%;
        font-size: $base-m-font-size;
        font-weight: 400;
        color: #495057;
        padding: 0 1.5rem;
        flex-wrap: nowrap;

        .select__placeholder, .select__single-value {
          top: auto;
          transform: none;
          font-size: $base-m-font-size;
          height: 100%;
          align-items: center;
          display: flex;
        }

        .select__placeholder {
          color: #757575;
        }

        .select__input {
          height: 4.5rem;
        }
      }
    }

    .select__menu {
      .select__menu-list {
        text-align: initial;

        .select__option {
          display: flex;
          align-items: center;

          img {
            height: 2.1rem;
            width: 2.8rem;
            margin-right: 1rem;
          }
        }
      }
    }
  }
}

.slots {
  .checkbox-container {
    .checkbox-wrapper {
      float: left;
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 600;

      .checkbox-input {
        display: none;
      }

      .checkbox-label {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #E0F7EF;
        color: #35E4A1;
        font-size: 1.4rem;
        padding: 1rem 2rem;
        width: 6rem;
        margin: 0 1rem 1rem 0;

        &:hover {
          cursor: pointer;
        }
      }

      .checkbox-checkmark {
        display: none;
      }

      &.unavailable {
        color: #666;
        background-color: #f4f4f4;
        text-decoration: line-through;
        cursor: pointer;
      }
    }
  }
}

.specialists-container {
  display: flex;
  flex-direction: column;

  .checkbox-container {
    .checkbox-wrapper {
      width: 100%;
      background-color: transparent;
      margin-left: 0;

      .checkbox-checkmark {
        top: unset;
      }
    }
  }
}

.aftercare-details-form {
  width: 100%;

  form {
    width: 100%;
  }
}

.event-calendar {
  label[for=date] {
    display: none;
  }
}

.react-datepicker-host {
  .react-datepicker-wrapper {
    .react-datepicker__input-container {
      input:disabled {
        background-color: rgb(242, 242, 242);
      }
    }

    &:after {
      z-index: 9;
      opacity: 0.5;
    }
  }
}

.subscription-plan-container {
  padding: 0 1.5rem;

  .subscription-plan-details {
    border-bottom: 0.1rem solid $card-border-bottom-color;
    margin-bottom: 1rem;
    padding-bottom: 1rem;

    .subscription-plan-title {
      font-weight: 700;
    }
  }
}

.subscription-files-container {
  padding: 0 1.5rem;
}


.month-calendar {
  width: unset;

  .react-datepicker__navigation {
    top: 1rem;

    &:hover {
      background-color: $theme-color;
    }
  }
  .react-datepicker__navigation--next {
    right: 0;
  }

  .react-datepicker__navigation--previous {
    left: 0;
  }

  .react-datepicker__day {
    &.react-datepicker__day--outside-month {
      pointer-events: none;
    }
  }
}

form {
  .input-group {
    .StripeElement {
      display: flex;
      align-items: center;
      height: 4rem;
      border-radius: 2rem;
      padding: .35rem 1.5rem;
      color: #49525b;
      background-color: white;
      border: 0.1rem solid #d2d8dd;
      width: 100%;

      &.StripeElement--invalid {
        border-color: #ff585f;
      }

      & > div {
        flex: 1;
      }
    }
  }
}

label[for=treatmentPlannerType] + .radio-control {
  display: flex;

  .radio-wrapper:first-child {
    margin-right: 3rem;
    .radio-label {
      background-color: $travel-plan-color;
    }
  }

  .radio-wrapper {
    .radio-label {
      text-transform: uppercase;
      background-color: $aftercare-color;
      color: $main-color;
      border-radius: $border-radius-big;
      padding: 0.3rem 0.7rem;
    }
  }
}

label[for=repeatEvent] + .radio-control {
  display: flex;

  .radio-wrapper:first-of-type {
    margin-right: 2rem;
  }
}


.daily-travel-plans {
  .custom-card {
    .custom-card-body {
      padding: 2rem 1.4rem 2rem;
    }
  }
}
